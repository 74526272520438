import {
    Box,
    Flex,
    HStack,
    Image,
    Link,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text,
    VStack,
} from '@chakra-ui/react'
import { useGetJobApplicationsQuery } from '@core/app/api/postsApi'
import { Localization } from '@core/app/types/globalApiType'
import { PostsItem } from '@core/app/types/postsApiType'
import { IconJobType } from '@core/assets/icons/job_type'

import appliedIcon from '@core/assets/images/applied_icon.svg'
import emptyPost from '@page/posts/assets/images/empty_post.svg'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import { getDateWithShortMonthName } from '@core/utils/helpers/getDateWithShortMonthName'
import locationIcon from '@page/company/assets/images/location.svg'
import locationAlterIcon from '@page/company/assets/images/alter_location.svg'
import jobAlterIcon from '@page/company/assets/images/alter_job_type.svg'
import timeIcon from '@page/company/assets/images/time.svg'
import sandClock from '@page/company/assets/images/sand_clock.svg'
import info from '@page/company/assets/images/info.svg'
import incompleteIcon from '@page/posts/assets/images/incomplete.svg'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import decodeHTMLEntities from '@core/utils/helpers/decodeHTMLEntities'

interface Props {
    postData: PostsItem[0] | undefined
}

export const HalfPost = ({ postData }: Props): JSX.Element => {
    const { data: applications } = useGetJobApplicationsQuery({})
    const { t } = useTranslation()
    const { appTheme } = useAppTheme()
    const [localization, setLocalization] = useState<Localization>({})
    const [applied, setApplied] = useState<boolean>(false)

    const mainThemeJobPostTextColor = appTheme ? `#${appTheme?.newSettings?.general?.JobPostText}` : undefined
    const currentPost = applications?.data?.find((item) => item.AdID === postData?.AdID)
    const formattedDate = postData ? getDateWithShortMonthName(postData.Expire, t) : ''

    const customPrefix: string = document.getElementById('__custom_route_data')?.getAttribute('data-prefix') ?? ''

    const getDifference = (expireDate: string) => {
        const date1 = new Date(expireDate)
        let date2 = new Date()
        // @ts-ignore
        date2 = date2.setHours(0, 0, 0, 0)
        // @ts-ignore
        const diffInMs = Math.abs(date2 - date1)

        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24))

        return diffInDays
    }

    const isApplied = () => {
        if (applications && applications.data && applications.data.length > 0 && postData) {
            if (currentPost?.Active === 1) {
                return (
                    <Flex
                        py={'2px'}
                        pl={'6px'}
                        pr={'12px'}
                        borderRadius={'24px'}
                        bg={'#00853D'}
                        gap={2}
                        alignItems={'center'}
                        cursor={'pointer'}
                    >
                        <Image src={appliedIcon} maxW={'16px'} alt={'applied icon'} />
                        <Text fontSize={'sm'} color={'text.white'} fontWeight={'semibold'}>
                            {t('1215')}
                        </Text>
                    </Flex>
                )
            }
            if (currentPost?.Active === 2) {
                return (
                    <Popover trigger={'hover'}>
                        <PopoverTrigger>
                            <Flex
                                py={'2px'}
                                pl={'6px'}
                                pr={'12px'}
                                borderRadius={'24px'}
                                bg={'#F07524'}
                                gap={2}
                                alignItems={'center'}
                                cursor={'pointer'}
                            >
                                <Image src={incompleteIcon} maxW={'16px'} alt={'incomplete icon'} />
                                <Text fontSize={'sm'} color={'text.white'} fontWeight={'semibold'}>
                                    {t('3226')}
                                </Text>
                            </Flex>
                        </PopoverTrigger>
                        <PopoverContent
                            border={'1px solid #E0E0E0'}
                            boxShadow={'0px 16px 40px 0px rgba(0, 0, 0, 0.08)'}
                        >
                            <PopoverArrow />
                            <PopoverBody>
                                <Text fontSize={'sm'} color={'text.main'} fontWeight={'medium'}>
                                    {t('7101')}
                                </Text>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                )
            }

            return
        }
    }

    let borderColor = 'transparent'
    if (currentPost?.Active === 1) {
        borderColor = '#00853D'
    } else if (currentPost?.Active === 2) {
        borderColor = '#F07524'
    }

    const daysDifference = postData ? +getDifference(postData.Expire) : 0

    const expirationMessage = postData
        ? (() => {
              const daysDifference = getDifference(postData.Expire)

              if (daysDifference === 0) {
                  return t('152') // Сьогодні
              } else if (daysDifference === 1) {
                  return t('153') // 1 day left
              } else if (daysDifference < 30) {
                  return `${daysDifference} ${t('4463')}` // X days left
              } else if (daysDifference < 365) {
                  const monthsDifference = Math.floor(daysDifference / 30)
                  if (monthsDifference === 1) {
                      return `${monthsDifference} ${t('8704')}` // 1 month left
                  } else {
                      return `${monthsDifference} ${t('8705')}` // X months left
                  }
              } else {
                  const yearsDifference = Math.floor(daysDifference / 365)
                  if (yearsDifference === 1) {
                      return `${yearsDifference} ${t('8706')}` // 1 year left
                  } else {
                      return `${yearsDifference} ${t('8707')}` // X years left
                  }
              }
          })()
        : null

    useEffect(() => {
        if (applications && applications.data && applications.data.length > 0 && postData) {
            const post = applications.data.find((item) => item.AdID === postData.AdID)
            if (post) {
                setApplied(true)
            }
        }
    }, [applications, postData])

    useEffect(() => {
        if (!appTheme) return
        if (appTheme && appTheme?.localization) {
            setLocalization(appTheme?.localization)
        }
    }, [appTheme])

    const getLogoSrc = (postData: PostsItem[0] | undefined, customSize: string = '420') => {
        if (!postData || !postData.Logos) return ''

        // Check if the 'custom' key exists and contains '[CUSTOM_SIZE]'
        if (postData.Logos.custom) {
            return postData.Logos.custom.replace('[CUSTOM_SIZE]', customSize)
        }

        // Fallback to 320 or AdLogo
        return postData.Logos[320] || postData.AdLogo || ''
    }

    return (
        <Box
            bg={'bg.1'}
            borderRadius={'8px'}
            overflow={'hidden'}
            p={4}
            w="100%"
            boxShadow={'0px 1px 6px 0px rgba(0,0,0,0.15)'}
            border={'2px solid'}
            borderColor={borderColor}
            cursor={'pointer'}
            transition={'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out'}
            _hover={{
                shadow: 'md',
                boxShadow: '0px 1px 6px 0px rgba(0,0,0,0.35)',
                transform: 'scale(1.005)',
                transitionTimingFunction: 'ease-in-out',
            }}
            onClick={() => {
                if (postData) location.href = `${window.location.origin}${customPrefix}/job/${postData?.AdID}`
            }}
        >
            {postData ? (
                <Flex gap={6} flexDirection={{ base: 'column', sm: 'row', md: 'column' }}>
                    <Flex
                        className={'JopPosts--HalfPost--Logo'}
                        // minW={'200px'}
                        // minH={'230'}
                        sx={{
                            width: { base: '100%', sm: '200px', md: '100%' },
                            // minWidth: { base: '100%', sm: '200px' },
                            height: { base: '100%', sm: '136px', md: '230px' },
                        }}
                        justifyContent={'center'}
                        alignItems={'center'}
                        cursor={'pointer'}
                        bg={postData.AdLogo ? 'transparent' : '#FAFAFA'}
                        border={postData.AdLogo ? 'none' : '1px solid #EAEAEA'}
                    >
                        {postData.AdLogo ? (
                            <LazyLoadImage
                                src={getLogoSrc(postData)}
                                effect={'blur'}
                                style={{
                                    // maxWidth: '200px',
                                    maxHeight: '230px',
                                    objectFit: 'contain',
                                }}
                                onClick={() =>
                                    (location.href = `${window.location.origin}${customPrefix}/job/${postData.AdID}`)
                                }
                                alt={postData.Name}
                            />
                        ) : (
                            <Image
                                src={emptyPost}
                                alt="No image available"
                                // boxSize="120px"
                                objectFit="contain"
                            />
                        )}
                    </Flex>
                    <VStack
                        gap={1}
                        alignItems={'start'}
                        justifyContent={'start'}
                        w={'full'}
                        minH={{ base: '100%', xl: '155px' }}
                    >
                        <Flex w={'full'} gap={4} alignItems={'start'} justifyContent={'flex-end'}>
                            <Link
                                fontSize={'xl'}
                                fontWeight={'semibold'}
                                color={mainThemeJobPostTextColor ? mainThemeJobPostTextColor : 'text.link'}
                                _hover={{ textDecoration: 'none' }}
                                href={`${window.location.origin}${customPrefix}/job/${postData.AdID}`}
                                mr={'auto'}
                                maxW={{ base: '275px', xl: '400px' }}
                            >
                                {decodeHTMLEntities(postData.Name)}
                            </Link>
                            {/* {isApplied()} */}
                        </Flex>
                        <HStack gap={1} flexWrap={'wrap'}>
                            <Text
                                fontSize={'sm'}
                                color={'text.thirdly'}
                                textTransform={'capitalize'}
                                display={'inline'}
                            >
                                {decodeHTMLEntities(postData.EmpName)}
                            </Text>
                            {postData.Positions > 1 && (
                                <Text
                                    fontSize={'sm'}
                                    color={'text.thirdly'}
                                    textTransform={'capitalize'}
                                    display={'inline'}
                                >
                                    ·{' '}
                                    {localization[11]
                                        ? `${postData.Positions} ${localization[11]}`
                                        : postData.Positions === 1
                                          ? `${postData.Positions} ${t('5369')}`
                                          : `${postData.Positions} ${t('2413')}`}
                                </Text>
                            )}
                        </HStack>
                        <Text
                            fontSize={'sm'}
                            fontWeight={'medium'}
                            color={'text.secondary'}
                            display="-webkit-box"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            w={'100%'}
                            css={{
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                            }}
                        >
                            {decodeHTMLEntities(postData.JobTitle)}
                        </Text>

                        <Flex
                            flexDirection={{ base: 'column', xl: 'column' }}
                            justifyContent={'space-between'}
                            w={'full'}
                            gap={{ base: 1, xl: 0 }}
                            mt={{ base: '0', xl: 'auto' }}
                        >
                            <Flex flexDirection={{ base: 'column', xl: 'column' }} w={'full'} gap={{ base: 1, xl: 0 }}>
                                <Flex flexDirection={{ base: 'column', xl: 'row' }} w={'full'} gap={{ base: 1, xl: 0 }}>
                                    {postData.Place && postData.WorkType !== 3 && (
                                        <Flex gap={1} mr={3} maxW={'180px'} alignItems={'center'}>
                                            <Image w={3} h={3} src={locationAlterIcon} alt={'location icon'} />
                                            <Text fontSize={'sm'} color={'text.secondary'}>
                                                {decodeHTMLEntities(postData.Place)}
                                            </Text>
                                        </Flex>
                                    )}
                                    {postData.Extent && (
                                        <Flex gap={1} alignItems={'center'}>
                                            <Image w={3} h={3} src={jobAlterIcon} alt={'job icon'} />
                                            <Text fontSize={'sm'} color={'text.secondary'}>
                                                {decodeHTMLEntities(postData.Extent)}
                                            </Text>
                                        </Flex>
                                    )}
                                </Flex>

                                <Flex gap={1} ml={{ base: '0', xl: '0' }} alignItems="center">
                                    {postData.ShowEndDate && (
                                        <Flex paddingTop="12px" gap={1} alignItems="center">
                                            <Image w={3} h={3} src={sandClock} alt="timer icon" />
                                            <Flex>
                                                {expirationMessage && (
                                                    <Text
                                                        ml={1}
                                                        mr={1}
                                                        fontSize="sm"
                                                        fontWeight="medium"
                                                        color="text.main"
                                                        textTransform={'lowercase'}
                                                    >
                                                        <Text
                                                            as="span"
                                                            fontSize="sm"
                                                            fontWeight="bold"
                                                            color={daysDifference < 7 ? 'text.warning' : 'text.main'}
                                                        >
                                                            {expirationMessage.split(' ').slice(0, 2).join(' ')}
                                                        </Text>{' '}
                                                        {expirationMessage.split(' ').slice(2).join(' ')}
                                                    </Text>
                                                )}
                                            </Flex>
                                            <Popover trigger="hover" preventOverflow={false}>
                                                <PopoverTrigger>
                                                    <Image w={3} h={3} src={info} alt="info icon" cursor="pointer" />
                                                </PopoverTrigger>
                                                <PopoverContent
                                                    border="none"
                                                    boxShadow="0px 16px 40px 0px rgba(0, 0, 0, 0.08)"
                                                    bg="#323234"
                                                    p="2px"
                                                    borderRadius="md"
                                                    width="auto"
                                                    maxWidth="200px"
                                                    minWidth="100px"
                                                >
                                                    <PopoverBody>
                                                        <Flex alignItems="center" justifyContent="center">
                                                            <Text
                                                                fontSize="sm"
                                                                color="white"
                                                                fontWeight="medium"
                                                                mr={1}
                                                            >
                                                                {t('8709')}
                                                            </Text>
                                                            <Text fontSize="sm" color="white" fontWeight="medium">
                                                                {formattedDate}
                                                            </Text>
                                                        </Flex>
                                                    </PopoverBody>
                                                </PopoverContent>
                                            </Popover>
                                            <Box w="24px" />
                                        </Flex>
                                    )}

                                    {isApplied() && (
                                        <Flex paddingTop="12px" alignItems="center">
                                            {isApplied()}
                                        </Flex>
                                    )}
                                </Flex>
                            </Flex>
                        </Flex>
                    </VStack>
                </Flex>
            ) : null}
        </Box>
    )
}
